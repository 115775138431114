import { getUserInfo, setUserInfo } from "@/api/UserService";
import Vue from "vue";

const initState = () => {
  return {
    isFetched: false,
    isFetching: false,
    data: {
      userId: null,
      contactId: null,
      userMail: null,
      nom: null,
      prenom: null,
      avatar: null,
      identifiant: null,
      portable: null,
      emailing: null,
      afficherVideoSecurite: null,
      paiementActif: null,
      nNbReservationAdherent: 0,
      nNbReservationAnimateur: 0,
      nNbReservationGestionnaireOrganisme: 0,
      nNbReservationGrandPublic: 0,
      remise: 0,
      fitArenaListeNoire: [],
    },
  };
};

export default {
  namespaced: true,
  namespace: "user",
  state: initState(),
  actions: {
    SET_INFO({ commit, dispatch }, userInfo) {
      commit("SET_INFO", userInfo);
      dispatch("GET_PROFIL_INFO");
    },
    RESET_INFO({ commit }) {
      commit("RESET_INFO");
    },
    async GET_PROFIL_INFO({ commit, state }) {
      if (!state.isFetched && !state.isFetching && state.data.contactId) {
        try {
          commit("setIsFetchingTrue");
          const result = await getUserInfo(state.data.contactId);

          if (result) {
            commit("SET_PROFIL_INFO_DETAIL", {
              userMail: result.sEmail,
              nom: result.sNom,
              prenom: result.sPrenom,
              avatar: result.sUrlAvatar,
              identifiant: result.sIdentifiant,
              portable: result.sPortable,
              emailing: result.nEmailing,
              afficherVideoSecurite: result.afficherVideoSecurite,
              paiementActif: result.paiementActif,
              remise: result.remise,
              nNbReservationAdherent:
                result.oReservation &&
                result.oReservation.nNbReservationAdherent !== undefined
                  ? parseInt(result.oReservation.nNbReservationAdherent)
                  : 0,
              nNbReservationAnimateur:
                result.oReservation &&
                result.oReservation.nNbReservationAnimateur !== undefined
                  ? parseInt(result.oReservation.nNbReservationAnimateur)
                  : 0,
              nNbReservationGestionnaireOrganisme:
                result.oReservation &&
                result.oReservation.nNbReservationGestionnaireOrganisme !==
                  undefined
                  ? parseInt(
                      result.oReservation.nNbReservationGestionnaireOrganisme
                    )
                  : 0,
              nNbReservationGrandPublic:
                result.oReservation &&
                result.oReservation.nNbReservationGrandPublic !== undefined
                  ? parseInt(result.oReservation.nNbReservationGrandPublic)
                  : 0,
            });
            commit("setFetchFinish");
          } else {
            commit("setFetchError");
          }
        } catch (err) {
          commit("setFetchError");
        }
      }
    },
    async SET_PROFIL_INFO({ commit, state, dispatch }, payload) {
      const response = await setUserInfo({
        sNom: payload.sNom,
        sPrenom: payload.sPrenom,
        sPortable: payload.sPortable,
        sEmail: payload.sEmail,
        sIdentifiant: payload.sIdentifiant,
        rAvatar: payload.rAvatar,
        nEmailing: payload.nEmailing,
        afficherVideoSecurite: payload.afficherVideoSecurite,
        paiementActif: payload.paiementActif,
        bEquipementAdapte: 0,
        nIdGrandPublic: state.data.contactId,
      });
      if (response && response.bSucces) {
        commit("setFetchedDirty");
        await dispatch("GET_PROFIL_INFO");
        const message = "Vos informations ont bien été mises à jour";
        if (screen.width > 640) {
          Vue.$toast.success(message);
        } else {
          Vue.$toast.success(message, {
            position: "top",
          });
        }
        return response;
      } else {
        if (
          response.aDoublon &&
          Array.isArray(response.aDoublon) &&
          response.aDoublon.length > 0
        ) {
          const doublon = response.aDoublon.shift();
          const message = `L'information du champ ${doublon} est déjà utilisée`;
          if (screen.width > 640) {
            Vue.$toast.error(message);
          } else {
            Vue.$toast.error(message, {
              position: "top",
            });
          }
        } else {
          const message =
            "Une erreur est survenue lors de la mise à jour de vos données";
          if (screen.width > 640) {
            Vue.$toast.error(message);
          } else {
            Vue.$toast.error(message, {
              position: "top",
            });
          }
        }
        throw response;
      }
    },
    SET_FIT_ARENA_LISTE_NOIRE({ commit }, fitArenaListeNoire) {
      commit("setFitArenaListeNoire", fitArenaListeNoire);
    },
  },
  mutations: {
    SET_INFO: (state, userInfo) => {
      state.data.userId = userInfo.userId;
      state.data.contactId = userInfo.contactId;
    },
    SET_PHONE: (state, phone) => {
      state.data.portable = phone;
    },
    RESET_INFO: (state) => {
      Object.assign(state, initState());
    },
    SET_PROFIL_INFO: (state, payload) => {
      state.data.userMail = payload.userMail;
      state.data.nom = payload.nom;
      state.data.prenom = payload.prenom;
      state.data.avatar = payload.avatar;
      state.data.portable = payload.portable;
      state.data.emailing = payload.emailing;
      state.data.identifiant = payload.identifiant;
      state.data.afficherVideoSecurite = payload.afficherVideoSecurite;
      state.data.paiementActif = payload.paiementActif;
      state.data = Object.assign({}, state.data);
    },
    SET_PROFIL_INFO_DETAIL: (state, payload) => {
      state.data.userMail = payload.userMail;
      state.data.nom = payload.nom;
      state.data.remise = payload.remise;
      state.data.prenom = payload.prenom;
      state.data.avatar = payload.avatar;
      state.data.identifiant = payload.identifiant;
      state.data.portable = payload.portable;
      state.data.emailing = payload.emailing;
      state.data.afficherVideoSecurite = payload.afficherVideoSecurite;
      state.data.paiementActif = payload.paiementActif;
      state.data.nNbReservationAdherent = payload.nNbReservationAdherent;
      state.data.nNbReservationAnimateur = payload.nNbReservationAnimateur;
      state.data.nNbReservationGestionnaireOrganisme =
        payload.nNbReservationGestionnaireOrganisme;
      state.data.nNbReservationGrandPublic = payload.nNbReservationGrandPublic;
      state.data = Object.assign({}, state.data);
    },
    setFetchError: (state) => {
      state.isFetched = false;
      state.isFetching = false;
    },
    setFetchFinish: (state) => {
      state.isFetched = true;
      state.isFetching = false;
    },
    setIsFetchingTrue: (state) => {
      state.isFetching = true;
    },
    setFetchedDirty: (state) => {
      state.isFetched = false;
      state.isFetching = false;
    },
    setFitArenaListeNoire: (state, payload) => {
      state.data.fitArenaListeNoire = payload;
    },
  },
  getters: {
    user_fetched: (state) => {
      return state.isFetched;
    },
    get_user: (state) => {
      return state.data;
    },
    get_user_remise: (state) => {
      return state.data.remise;
    },
    get_user_id: (state) => {
      return state.data.userId;
    },
    get_user_mail: (state) => {
      return state.data.userMail;
    },
    get_contact_id: (state) => {
      return state.data.contactId;
    },
    get_nb_reservation_adherent: (state) => {
      return state.data.nNbReservationAdherent;
    },
    get_nb_reservation_animateur: (state) => {
      return state.data.nNbReservationAnimateur;
    },
    get_nb_reservation_gestionnaire_organisme: (state) => {
      return state.data.nNbReservationGestionnaireOrganisme;
    },
    get_nb_reservation_grand_public: (state) => {
      return state.data.nNbReservationGrandPublic;
    },
    get_fitArenaListeNoire: (state) => {
      return state.data.fitArenaListeNoire;
    },
  },
};
