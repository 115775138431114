<template>
  <div class="w-full p-4">
    <div v-show="isLoading" class="flex flex-1 justify-center">
      <DoiSpinner :spinnerSize="1" />
    </div>
    <div v-show="!isLoading" v-if="slotsData.length > 0">
      <div
        v-for="(slot, index) in slotsData"
        :key="slot.nIdCreneau"
        class="border rounded-lg pb-3 mb-4 bg-planning flex flex-col"
      >
        <PlanningLightItem
          @clickTimeslot="clickTimeslot"
          :nbPersonneConseille="nbPersonneConseille"
          :day="slot"
          :setOpen="index === 0"
        />
      </div>
    </div>
    <div v-else class="flex justify-center text-center px-6">
      <DoiText :fontFamily="1" :textColor="0" :fontSize="3" class="uppercase"
        >Aucun créneau disponible pour ce sport</DoiText
      >
    </div>
  </div>
</template>

<script>
import PlanningLightItem from "./PlanningLightItem";
import dayjs from "dayjs";

export default {
  name: "PlanningLight",
  data() {
    return {
      lastDate: null,
      lastIndex: -1,
      slotsData: [],
      reservedSlotsData: [],
    };
  },
  props: {
    slots: Array,
    isLoading: Boolean,
    nbPersonneConseille: String,
  },
  components: {
    PlanningLightItem,
  },
  methods: {
    clickTimeslot(idCreneau) {
      this.$emit("clickTimeslot", idCreneau);
    },
    compareDate(a, b) {
      const dateA = new Date(a.start).getTime();
      const dateB = new Date(b.start).getTime();
      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    },
  },
  watch: {
    slots(value) {
      this.slotsData = [];
      this.lastIndex = -1;
      this.lastDate = null;

      const copyArray = [...value];
      copyArray.sort(this.compareDate).forEach((slot) => {
        if (slot.statut === "disponible" || slot.statut === "panier") {
          const currentDate = dayjs(slot.start).format("YYYY-MM-DD");

          const newEntry = {
            sLibelle: dayjs(slot.start).format("dddd D MMMM"),
            aSlot: [],
          };

          if (this.lastDate !== currentDate) {
            this.slotsData.push(newEntry);
            this.lastIndex += 1;
          }

          this.slotsData[this.lastIndex].aSlot.push(slot);
          this.lastDate = currentDate;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.bg-planning {
  background-color: $doi-bg-lightgray-color;
}
</style>
