<template>
  <NewModal
    title="AJOUT RÉDUCTION"
    :open="openUserDiscount"
    size="xs"
    @close-modal="$emit('closeModal')"
  >
    <template #modal_header>
      <DoiText
        :fontSize="4"
        :textColor="1"
        :fontFamily="0"
        class="uppercase ml-2"
        >AJOUT RÉDUCTION</DoiText
      >
    </template>
    <template slot="modal_content">
      <div class="flex flex-col gap-10 px-4">
        <div>
          <DoiText :textColor="1" :fontSize="3" :fontFamily="4" class="mb-2"
            >Mes réductions</DoiText
          >
          <SelectSingle
            v-if="itemsDiscounts.length > 0"
            title="Réductions"
            :items="itemsDiscounts"
            v-model="selectedDiscount"
            @update:modelValue="updateDiscount"
          />
          <div v-else class="p-3 border border-gray-300 rounded-lg text-sm">
            <DoiText :textColor="1" :fontSize="3" :fontFamily="4"
              >Aucune réduction disponible</DoiText
            >
          </div>
        </div>
        <div>
          <DoiText :textColor="1" :fontSize="1" :fontFamily="0"
            >La réduction sera appliquée automatiquement sur le montant de votre
            paiement après que la réservation soit passée.</DoiText
          >
        </div>
      </div>
    </template>
    <template #modal_footer>
      <DoiButton
        v-if="itemsDiscounts.length > 0"
        @click="validate"
        class="mx-auto w-1/2"
        :fontFamily="6"
        :disabled="selectedDiscount === ''"
        >VALIDER</DoiButton
      >
    </template>
  </NewModal>
</template>

<script>
import NewModal from "@/components/molecules/NewModal.vue";
import SelectSingle from "@/components/atomic/Select.vue";

export default {
  name: "DiscountModal",
  data() {
    return {
      selectedDiscount: "",
    };
  },
  props: {
    openUserDiscount: Boolean,
    itemsDiscounts: Array,
    discount: String,
  },
  components: { NewModal, SelectSingle },
  mounted() {
    this.selectedDiscount = this.discount;
  },
  methods: {
    updateDiscount(item) {
      this.selectedDiscount = item;
      this.$emit("updateDiscount", this.selectedDiscount);
    },
    validate() {
      this.$emit("validateDiscount");
    },
  },
};
</script>
