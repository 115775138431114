<template>
  <div id="cookies" v-if="this.cookie === null || this.cookie === '1'">
    <div
      class="w-full flex-col flex sm:flex-row bg-white px-4 py-1 fixed justify-center bottom-4 items-center"
    >
      <DoiText :fontFamily="5" :textColor="1" :fontSize="1" class="w-9/12"
        >Notre site utilise des traceurs (cookies) strictement nécessaires au
        fonctionnement de notre site internet, ainsi que des cookies pour
        optimiser et personnaliser votre navigation, réaliser des statistiques
        et mesurer l'audience.</DoiText
      >
      <DoiButton
        :textColor="1"
        :bgColor="4"
        :fontFamily="5"
        class="cookies-btn my-2 sm:my-0"
        @click="submit('accept')"
        >Tout accepter</DoiButton
      >
      <DoiButton
        :textColor="1"
        :bgColor="4"
        :fontFamily="5"
        class="cookies-btn"
        @click="submit('decline')"
        >Tout refuser</DoiButton
      >
      <!-- <DoiButton :textColor="1" class="cookies-btn">Paramétrer</DoiButton> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "DoiCookiesNavbar",
  data() {
    return {
      isGtagEnabled: localStorage.getItem("gtagEnabled") ? false : true,
      cookie: localStorage.getItem("cookie_value"),
    };
  },
  methods: {
    submit(action) {
      const gtagEnabled = action === "accept" ? true : false;
      switch (action) {
        case "accept":
          localStorage.setItem("gtagEnabled", gtagEnabled);
          this.isGtagEnabled = gtagEnabled;
          this.cookie = localStorage.setItem("cookie_value", "0");
          break;
        case "decline":
          localStorage.setItem("gtagEnabled", gtagEnabled);
          this.isGtagEnabled = gtagEnabled;
          this.cookie = localStorage.setItem("cookie_value", "1");
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.bg-white {
  border-top: 2px solid $doi--color-color0;
  border-bottom: 2px solid $doi--color-color0;
  z-index: 1;
}

.cookies-btn {
  cursor: pointer;
  margin-right: 9px;
  margin-left: 5px;
  border: 1px solid $doi--color-color0;
}
</style>
