<template>
  <div class="container w-full max-w-6xl mx-auto">
    <!-- PANIER AVEC ELEMENT -->
    <div class="w-full">
      <router-link to="/" class="flex items-center mx-4">
        <img src="@/assets/img/back-v2.png" alt="retour page precedente" />
        <DoiText
          type="span"
          class="ml-2"
          :textColor="3"
          :fontFamily="5"
          :fontSize="3"
          >RETOUR</DoiText
        >
      </router-link>
      <div class="flex flex-1 my-4 items-center">
        <div class="red-line"></div>
        <DoiText
          :fontSize="5"
          :textColor="1"
          :fontFamily="0"
          class="uppercase ml-2"
          >MON PANIER</DoiText
        >
      </div>
      <template v-if="cart.contenu && cart.contenu.length > 0">
        <div v-if="isLoading">
          <div class="flex flex-1 justify-center items-center">
            <DoiSpinner :spinnerSize="1" />
          </div>
        </div>
        <CartList :cartList="cart" @deleteCartElement="clickDeleteElement" />
      </template>
      <DoiText
        v-else
        :textColor="0"
        :fontSize="4"
        :fontFamily="1"
        class="uppercase mx-4"
        >Aucune réservation dans votre panier</DoiText
      >
      <div class="p-8 flex flex-col lg:flex-row w-full justify-end">
        <DoiButton
          link="/#fit-arena-list"
          class="lg:mr-4 mb-4 lg:mb-0 border-button"
          :fontFamily="5"
          :bgColor="4"
          :textColor="0"
          >AJOUTER DES CRÉNEAUX</DoiButton
        >
        <DoiButton
          v-if="cart.contenu && cart.contenu.length > 0"
          @click="goToPayment()"
          class="lg:mr-2"
          :bgHover="1"
          :fontFamily="5"
          >VALIDER</DoiButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import CartList from "@/views/cart/CartList";

import { mapActions } from "vuex";

export default {
  name: "CartRecap",
  data() {
    return {
      cart: [],
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  components: { CartList },
  async mounted() {
    await this.$store.dispatch("CartModule/INIT_CART2");
    this.cart = await this.$store.getters["CartModule/get_cart"];
  },
  computed: {
    isLoading() {
      return this.$store.getters["CartModule/isCartLoading"];
    },
    isReadyToPay() {
      return this.$store.getters["CartModule/isCartLoading"];
    },
  },
  methods: {
    ...mapActions("CartModule", ["deleteCartElement2"]),
    async clickDeleteElement(id) {
      await this.deleteCartElement2(id);
      await this.$store.dispatch("CartModule/INIT_CART2");
      this.cart = await this.$store.getters["CartModule/get_cart"];
    },
    goToPayment() {
      this.$router.push("/cart/payment");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.red-line {
  border-top: 1px solid $doi--color-color0;
  width: 40px;
}
.border-button {
  border: 1px solid $doi--color-color0;
}
</style>
