<template>
  <!-- payment form -->
  <div class="paymentForm">
    <div class="kr-embedded" :kr-form-token="formToken">
      <!-- payment form fields -->
      <div class="kr-pan"></div>
      <div class="kr-expiry"></div>
      <div class="kr-security-code"></div>
      <!-- payment form submit button -->
      <button class="kr-payment-button validation"></button>
      <!-- error zone -->
      <div class="kr-form-error"></div>
    </div>
  </div>
</template>

<script>
// https://payzen.io/en-EN/rest/V4.0/javascript/spa/
// https://github.com/lyra/embedded-form-glue/tree/master/examples/vuejs/minimal-example
export default {
  name: "paymentForm",
  props: {
    formToken: String,
    v2: String,
  },
  methods: {
    onClicked() {
      setTimeout(() => {
        this.$emit("close");
      }, 4000);
    },
    onPaid(event) {
      this.$emit("close");
      if (event.clientAnswer.orderStatus === "PAID") {
        KR.removeForms();
        const message = "Moyen de paiement mis à jour";
        if (screen.width > 640) {
          this.$toast.success(message);
        } else {
          this.$toast.success(message, {
            position: "top",
          });
        }
        if (this.v2 === "false")
          this.$store.dispatch("PaymentModule/retrievePaymentAlias");
        else this.$store.dispatch("PaymentModule/retrievePaymentAliasV2");
      } else {
        const message = "Erreur lors de la validation du moyen de paiement";
        if (screen.width > 640) {
          this.$toast.error(message);
        } else {
          this.$toast.error(message, {
            position: "top",
          });
        }
      }
    },
  },
  watch: {
    formToken: {
      immediate: true,
      handler(value) {
        // Set form token
        KR.setFormToken(value);
        // Add listener for submit event
        KR.onSubmit(this.onPaid);
        // Add listener for click event
        KR.button.onClick(this.onClicked);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

/* bouton VALIDER  */
.validation {
  background-color: $doi--button-color0;
  color: #fff;
  padding: 0.5em 1em;
  margin-top: 1em;
  font-family: $doi--text-font-family2;
}
.validation:hover {
  background-color: $doi--button-color1;
}

/* for debug only */
#krtoolbar {
  z-index: 50000 !important;
}
</style>
