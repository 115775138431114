import {
  getReservationGroupe,
  deleteParticipant,
  valideInvitation,
  sendInvitationV2,
} from "@/api/BookingService";
import dayjs from "dayjs";
import Vue from "vue";

const initState = () => {
  return {
    isFetched: false,
    data: {
      reservationList: [],
      idFirstReservationPanier: "",
    },
  };
};

export default {
  name: "ReservationList",
  namespaced: true,
  state: initState(),
  actions: {
    async retrieveReservations({ commit }) {
      try {
        const nTamponDebut = dayjs().unix() - 60 * 15;
        let nTamponFin;
        const response = await getReservationGroupe({
          nTamponDebut,
          nTamponFin,
        });
        const reservations = Object.values(response.aReservations);
        commit("setReservationList", reservations);
      } catch (err) {
        commit("setFetchedOnError");
      }
    },
    async deleteParticipantInResa({ dispatch }, { idResa, idInvite }) {
      await deleteParticipant(idResa, idInvite);
      dispatch("retrieveReservations");
    },
    async valideInvitation(
      { dispatch },
      { sIdReservation, sJetonInvitation, aReponse }
    ) {
      const result = await valideInvitation({
        sIdReservation,
        sJetonInvitation,
        aReponse,
      });

      if (result !== undefined && result !== null && result.bSucces) {
        if (result.sLibelleMessage) {
          if (screen.width > 640) {
            Vue.$toast.info(result.sLibelleMessage);
          } else {
            Vue.$toast.info(result.sLibelleMessage, {
              position: "top",
            });
          }
        } else if (aReponse.length === 1) {
          const message = "Le contact a bien été ajouté à la réservation";
          if (screen.width > 640) {
            Vue.$toast.success(message);
          } else {
            Vue.$toast.success(message, {
              position: "top",
            });
          }
        } else {
          const message = "Les contacts ont bien été ajoutés à la réservation";
          if (screen.width > 640) {
            Vue.$toast.success(message);
          } else {
            Vue.$toast.success(message, {
              position: "top",
            });
          }
        }
      }
      dispatch("retrieveReservations");
    },
    async sendInvitation({ dispatch }, params) {
      const result = await sendInvitationV2(params);

      if (result !== undefined && result !== null) {
        if (result.bSucces) {
          const message = "L'invitation a bien été envoyée";
          if (screen.width > 640) {
            Vue.$toast.success(message);
          } else {
            Vue.$toast.success(message, {
              position: "top",
            });
          }
          dispatch("retrieveReservations");
        }
      }
    },
    setIdFirstReservationPanier({ state }, idFirstReservationPanier) {
      state.data.idFirstReservationPanier = idFirstReservationPanier;
    },
  },
  mutations: {
    setReservationList(state, payload) {
      Vue.set(state.data, "reservationList", [...payload]);
      state.data.reservationList.splice(state.data.reservationList.length);
      state.isFetched = true;
    },
    setReservationPartageable(state, { id_reservation, statut_partage }) {
      state.data.reservationList.find(
        (resa) => resa.id_reservation === id_reservation
      ).jeton_diffusion = statut_partage;
    },
    resetStore: (state) => {
      Object.assign(state, initState());
    },
    setFetchedOnError: (state) => {
      state.isFetched = true;
    },
  },
  getters: {
    reservationList: (state) => {
      return state.data.reservationList;
    },
    idFirstReservationPanier: (state) => {
      return state.data.idFirstReservationPanier;
    },
    isFetched: (state) => {
      return state.isFetched;
    },
    isShared: (state) => (id) =>
      state.data.reservationList.find((resa) => resa.id_reservation === id),
  },
};
