import axios from "axios";
import Store from "@/store";
import errors from "@/data/errors.json";
import Vue from "vue";
import store from "@/store/index";

const instance = axios.create({
  // baseURL: process.env.VUE_APP_API_URL
});

instance.interceptors.request.use(
  (config) => {
    const token = store.getters["AuthModule/get_token"];
    const token2 = store.getters["AuthModule/get_token_2"];
    if (config.secure) {
      if (config.url.includes(process.env.VUE_APP_API_URL) && token) {
        config.headers.Authorization = `Bearer ${token}`;
      } else if (
        config.url.includes(process.env.VUE_APP_API_URL_V2_AUTH) &&
        token2
      ) {
        config.headers.Authorization = `Bearer ${token2}`;
      }
    }

    return config;
  },
  (err) => {
    Promise.reject(err);
  }
);

instance.interceptors.response.use(
  (res) => {
    if (
      res.config.method === "put" &&
      res.config.url.includes("reservation/annulation/gp")
    ) {
      // catcher l'erreur annulation reservation v2 (retour back 'null') ==> TEMPORAIRE
      return;
    }
    if (res !== undefined) {
      if (res.bJwtSucces !== undefined && res.bJwtSucces === false) {
        Promise.reject(res);
      }
      if (
        (res.config.method === "delete" || res.config.method === "post") &&
        res.status === 204
      ) {
        return;
      }
      if (res.data !== undefined && res.data.szErreur === undefined) {
        if (res.data.sCodeErreur !== undefined) {
          if (errors[res.data.sCodeErreur] !== undefined) {
            Vue.$toast.error(errors[res.data.sCodeErreur]);
          } else {
            if (screen.width > 640) {
              Vue.$toast.error("Une erreur inconnue s'est produite");
            } else {
              Vue.$toast.error("Une erreur inconnue s'est produite", {
                position: "top",
              });
            }
          }
          Promise.reject(res);
        } else {
          return res.data;
        }
      } else if (res.data.szErreur) {
        Vue.$toast.error(errors[res.data.szErreur]);
        Promise.reject(res);
      } else {
        Promise.reject(res);
      }
    } else {
      Promise.reject(res);
    }
  },
  (err) => {
    const res = err.response;
    if (res.config.url.includes("auth") && res.status === 401) {
      Store.dispatch("AuthModule/DECONNEXION");
      if (screen.width > 640) {
        Vue.$toast.error("Connexions en échec");
      } else {
        Vue.$toast.error("Connexions en échec", {
          position: "top",
        });
      }
      return;
    }
    if (res.status === 422) {
      if (res.data.detail) {
        if (screen.width > 640) {
          Vue.$toast.error(res.data.detail);
        } else {
          Vue.$toast.error(res.data.detail, {
            position: "top",
          });
        }
        return;
      }
      if (res.data.violations) {
        if (screen.width > 640) {
          Vue.$toast.error(res.data.violations[0].message);
        } else {
          Vue.$toast.error(res.data.violations[0].message, {
            position: "top",
          });
        }
        return;
      }
    }
    if (res.status === 409) {
      if (screen.width > 640) {
        Vue.$toast.error(res.data.szErreur);
      } else {
        Vue.$toast.error(res.data.szErreur, {
          position: "top",
        });
      }
      return;
    }
    if (res.status === 418) {
      if (screen.width > 640) {
        Vue.$toast.error(res.data["hydra:description"]);
      } else {
        Vue.$toast.error(res.data["hydra:description"], {
          position: "top",
        });
      }
      return;
    }
    if (res.status === 400) {
      if (res.config.url.includes("annulation/gp")) {
        // catcher l'erreur annulation reservation v2 pour ne pas
        // qu'il y ait d'erreur affichée pour les utilisateurs
        return;
      }
      Store.dispatch("AuthModule/DECONNEXION");
      if (screen.width > 640) {
        Vue.$toast.clear();
        Vue.$toast.error("Session expirée, veuillez vous reconnecter.");
      } else {
        Vue.$toast.clear();
        Vue.$toast.error("Session expirée, veuillez vous reconnecter.", {
          position: "top",
        });
      }
    } else {
      if (res.data !== undefined && res.data.szErreur === undefined) {
        if (res.data.sCodeErreur !== undefined) {
          if (errors[res.data.sCodeErreur] !== undefined) {
            Vue.$toast.error(errors[res.data.sCodeErreur]);
          } else {
            if (screen.width > 640) {
              Vue.$toast.error("Une erreur inconnue s'est produite");
            } else {
              Vue.$toast.error("Une erreur inconnue s'est produite", {
                position: "top",
              });
            }
          }
        }
      } else if (res.data.szErreur) {
        Vue.$toast.error(errors[res.data.szErreur]);
      }
    }
    Promise.reject(res);
  }
);

export default instance;
