<template>
  <nav
    class="w-full absolute lg:bg-transparent"
    :class="floating ? 'absolute' : 'relative'"
  >
    <Sidebar
      :open="openResponsiveSidebar"
      @closeSidebar="openResponsiveSidebar = false"
    />
    <div
      :class="floating ? 'lg:pl-32' : 'lg:pl-0'"
      class="lg:container lg:mx-auto lg:pr-2 lg:pl-10 lg:pt-8 lg:pb-5 flex justify-between items-center"
    >
      <router-link to="/" class="hidden lg:flex">
        <img
          class="w-52 logo"
          src="@/assets/img/logo-fitarena.png"
          alt="Fit Arena"
        />
      </router-link>
      <div class="flex-1 items-center justify-end hidden lg:flex">
        <ul
          class="flex justify-between link-navigation font-navigation text-sm lg:text-lg 2k:text-xl 4k:text-5xl"
        >
          <li>
            <router-link
              to="/#fit-arena-list"
              class="top-menu-link"
              :class="getCurrentRoute === '/' ? 'active' : ''"
              >RÉSERVER</router-link
            >
          </li>
          <li>
            <router-link
              to="/mon-parcours-dans-la-salle"
              class="top-menu-link"
              :class="
                getCurrentRoute === '/mon-parcours-dans-la-salle'
                  ? 'active'
                  : ''
              "
              >MON PARCOURS DANS LA SALLE</router-link
            >
          </li>
        </ul>
        <HeaderConnect />
      </div>
    </div>
    <div class="flex lg:hidden px-6 pt-16 justify-between items-center">
      <router-link to="/" class="logo-sm">
        <img width="150" src="@/assets/img/logo-fitarena.png" />
      </router-link>
      <HeaderConnectDefault />
    </div>
  </nav>
</template>

<script>
import Sidebar from "@/views/holding/components/Sidebar";
import HeaderConnect from "@/components/organisms/HeaderConnect";
import HeaderConnectDefault from "@/components/organisms/HeaderConnectDefault";

export default {
  data() {
    return {
      openResponsiveSidebar: false,
      openCartRecap: false,
    };
  },
  props: {
    floating: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Sidebar,
    HeaderConnect,
    HeaderConnectDefault,
  },
  computed: {
    getCurrentRoute() {
      if (this.$route && this.$route.path) return this.$route.path;
      else return "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.link-navigation li a {
  margin: 5px 20px;
  display: block;
  padding-bottom: 5px;
}
.top-menu-link.active {
  color: $doi--color-color0;
  border-bottom: solid 2px $doi--color-color0;
}

.font-navigation {
  font-family: $doi--text-font-family2;
  color: $doi--color-color4;
}

@media (max-width: 400px) {
  .logo-sm {
    width: 100px;
  }
}

@media (min-width: $doi-breakpoint-extra-large-screen) {
  .logo {
    width: 30rem;
  }
}
</style>
